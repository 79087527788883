import { Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { getauditHistory } from "../apis/obligationApi";


const ActionHistory = ({ id }) => {
  const [configs, setConfigs] = useState({
    loading: true,
    history: [],
  });
  useEffect(() => {
    const getHistory = async () => {
      const response = await getauditHistory({ id });
      console.log("history: ", response);
      setConfigs({ loading: false, history: response.auditHistory });
    };
    getHistory();
  }, [id]);
  return (
    <div className="w-full  p-2">
        {configs.history && configs.history.map((tran)=>
        <div className="rounded bg-gray-100 p-2 mb-1">
        <div className="flex justify-between">
          <Text className="text-xs font-bold">{tran.doneby}</Text>
          <Text className="text-xs font-semibold">{(tran.attime).split('T')[0]}</Text>
        </div>

        <Text>{tran.extraCol1}</Text>
      </div>
    )}
      
      
    </div>
  );
};

export default ActionHistory;
