import {
  Button,
  Divider,
  SegmentedControl,
  Select,
  Skeleton,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useEffect, useState } from "react";
import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";
import {
  getStandardObligations,
} from "../apis/userApis";
import { converDateToISO, convertToISO8601 } from "./utilities";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../redux/authSlice";
import { createObligationApi  } from "../apis/obligationApi";
import { getFile, getReadUrl } from "../apis/fileApi";

const LegacyAgreementForm2 = ({ setUiConfigs, details, view }) => {
  const [standardObligations, setStandardObligations] = useState(null);
  const [options, setOptions] = useState(["select"]);
  const [createobligation, setCreateObligation] = useState(false);
  const [file, setFile] = useState(null);
  console.log(details);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  //   const currentUrl = window.location.href;
  // console.log(currentUrl);
  console.log("details: ", details);
  const [url, setUrl] = useState(null);
  const [mimeType, setMimeType] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const attachedFile = async () => {
      const response = await getFile({ id: details.agreementId });
      console.log("response: ", response);
      setFile(response.file);
    };
    attachedFile();
  }, [details.agreementId]);

  useEffect(()=>{
    const getObligationsOptions = async()=>{
      const response = await getStandardObligations();
      setStandardObligations(response.obligationData);
      if(response.obligationData.length > 0){
        setOptions(response.outputArray);
      }
    }
    getObligationsOptions();
  }, [])

  const [obligationData, setObligationData] = useState({
    clNo: "",
    title: "",
    desc: "",
    actFrequency: 1,
    recFrequency: 7,
    startDate: "",
    endDate: details.endDate,
    department: 1,
    reminderTime: 1,
    remarks: "",
    businessUnit: details.businessUnit,
    local: details.local,
    connectedAgreement: details.agreementId,
    connectedParty: details.secondParty,
    extraCol1: details.typeOfAgreement,
    createdBy: userData.name,
    createdId: userData.id,
    createdByEmail: userData.email,
  });
  
  console.log(details);
  const drive =  "https://drive.google.com/";
  useEffect(() => {
    if (file) {
      const getPreviewUrl = async ()=>{
      const intialurl = file.hostedLink;
      if(intialurl.slice(0,10) === drive.slice(0,10)){
        setUrl(intialurl);
        setLoading(false);
      }else{
        console.log("intialUrl = ", intialurl);
      setMimeType(file.mimeType);
      const key = file.extraCol1;
      const response = await getReadUrl({hostedLink: file.hostedLink});
      if(response.success){
        setUrl(response.signedUrl);
        setLoading(false);
      }
      
      }
    }
    getPreviewUrl();
    }
  }, [file]);
  console.log("file: ", file);
  const handleCreate = (newItem) => {
    setOptions([...options, newItem]);
    setObligationData({ ...obligationData, title: newItem });
  };
  const createNewObligation = async (e) => {
    e.preventDefault();
    const response = await createObligationApi({ body: obligationData });
    if (response.success) {
      console.log("obligation created");
      setCreateObligation(false);
      setObligationData({
        clNo: "",
        title: "",
        desc: "",
        actFrequency: 1,
        recFrequency: 7,
        startDate: "",
        endDate: details.endDate,
        department: 1,
        reminderTime: 1,
        remarks: "",
        businessUnit: details.businessUnit,
        local: details.local,
        connectedAgreement: details.agreementId,
        connectedParty: details.secondParty,
        extraCol1: details.typeOfAgreement,
        createdBy: userData.name,
        createdId: userData.id,
        createdByEmail: userData.email,
      });
    }
  };

  console.log("obligation data: ", obligationData);
  return (
    <>
      <div>
        {file && url && mimeType && !loading && (
          <iframe
            src={url}
            type={mimeType}
            width="100%"
            height="400px"
            title="Agreement View"
          />
        )}
        {loading && <Skeleton width={"100%"} height={"400px"} />}
        <Button onClick={() => setCreateObligation(true)} className="mt-2">
          add obligation
        </Button>
        {createobligation && (
          <>
            <form
              onSubmit={(e) => createNewObligation(e)}
              className=" w-full flex flex-row gap-1 mt-2"
            >
              <TextInput
                required
                labelProps={{
                  sx: {
                    fontSize: ".75rem"
                  }
                }}
                placeholder="clause no."
                label="clause no."
                className="text-center rounded-sm  w-1/12"
                value={obligationData.clNo}
                onChange={(e) =>
                  setObligationData({ ...obligationData, clNo: e.target.value })
                }
              />
              <Select
                required
                labelProps={{
                  sx: {
                    fontSize: ".75rem"
                  }
                }}
                placeholder="title"
                label="title"
                className="text-center rounded-sm w-2/12"
                value={obligationData.title}
                data={options}
                searchable
                creatable
                getCreateLabel={(query) => `+ Add "${query}"`}
                onCreate={handleCreate}
                onChange={(val) =>{
                  const selectedObligation = standardObligations.find(
                    obligation => obligation.title === val
                  );
                  if(selectedObligation){
                    setObligationData({
                      ...obligationData,
                      title: val,
                      desc: selectedObligation.desc,
                      actFrequency: selectedObligation.actFrequency,
                      recFrequency: selectedObligation.recFrequency,
                      department: selectedObligation.department,
                      reminderTime: selectedObligation.reminderTime,
                    })
                  }
                }
                  // setObligationData({
                  //   ...obligationData,
                  //   title: val,
                  // })
                }
              />
              <TextInput
                required
                labelProps={{
                  sx: {
                    fontSize: ".75rem"
                  }
                }}
                value={obligationData.desc}
                placeholder="description"
                label="description"
                className="text-center rounded-sm w-2/12"
                onChange={(e) =>
                  setObligationData({ ...obligationData, desc: e.target.value })
                }
              />
              <Select
                required
                labelProps={{
                  sx: {
                    fontSize: ".75rem"
                  }
                }}
                label="act. frequency"
                className="text-center w-1/12"
                placeholder="select act. frequency"
                data={[
                  { label: "one-time", value: 1 },
                  { label: "on-going", value: 2 },
                  { label: "recurring", value: 3 },
                ]}
                value={obligationData.actFrequency}
                onChange={(val) =>
                  setObligationData({ ...obligationData, actFrequency: val })
                }
              />
              {obligationData.actFrequency !== 1 && (
                <Select
                  required
                  labelProps={{
                    sx: {
                      fontSize: ".75rem"
                    }
                  }}
                  value={obligationData.recFrequency}
                  label="rec. frequency"
                  className="text-center w-1/12"
                  placeholder="select rec. frequency"
                  data={[
                    { label: "weekly", value: 7 },
                    {label: "fortnight", value: 14},
                    { label: "monthly", value: 30 },
                    { label: "quarterly", value: 90 },
                    {label: "half-yearly", value: 182},
                    { label: "annually", value: 365 },
                  ]}
                  onChange={(val) =>
                    setObligationData({ ...obligationData, recFrequency: val })
                  }
                />
              )}
              <DatePicker
                required
                labelProps={{
                  sx: {
                    fontSize: ".75rem"
                  }
                }}
                value={obligationData.startDate}
                minDate={new Date()}
                label="start date"
                className="text-center w-1/12"
                placeholder="start date"
                onChange={(val) =>
                  setObligationData({
                    ...obligationData,
                    startDate: convertToISO8601(converDateToISO(val)),
                  })
                }
              />
              {obligationData.actFrequency === 1 && (
                <DatePicker
                  minDate={new Date()}
                  required
                  labelProps={{
                    sx: {
                      fontSize: ".75rem"
                    }
                  }}
                  label="end date"
                  className="text-center w-1/12"
                  placeholder="end date"
                  value={
                    obligationData.recFrequency
                  }
                  onChange={(val) =>
                    setObligationData({ ...obligationData, endDate: val })
                  }
                />
              )}

              <Select
                required
                labelProps={{
                  sx: {
                    fontSize: ".75rem"
                  }
                }}
                label="department"
                className="text-center w-1/12"
                placeholder="department"
                value={obligationData.department}
                data={[
                  { label: "FINANCE", value: 1 },
                  { label: "HR", value: 2 },
                  { label: "IT", value: 3 },
                  { label: "TAXATION", value: 4 },
                  { label: "MARKETING", value: 5 },
                  { label: "ACCESSORIES", value: 6 },
                  { label: "SPARES", value: 7 },
                  {label: "OPERATIONS", value: 8}
                ]}
                onChange={(val) =>
                  setObligationData({ ...obligationData, department: val })
                }
              />
              <Select
                required
                labelProps={{
                  sx: {
                    fontSize: ".75rem"
                  }
                }}
                label="reminder time"
                className="text-center w-1/12"
                placeholder="reminder time"
                value={obligationData.reminderTime}
                data={[
                  { label: "1-day", value: 1 },
                  { label: "2-days", value: 2 },
                  { label: "3-days", value: 3 },
                  { label: "4-days", value: 4 },
                  { label: "5-days", value: 5 },
                  { label: "6-days", value: 6 },
                  { label: "7-days", value: 7 },
                  { label: "15-days", value: 15 },
                  { label: "30-days", value: 30 },
                  { label: "45-days", value: 45 },
                  { label: "60-days", value: 60 },
                  { label: "90-days", value: 90 },
                ]}
                onChange={(val) =>
                  setObligationData({ ...obligationData, reminderTime: val })
                }
              />
              <Button type="submit" className="mt-6 w-1/12">
                save
              </Button>
            </form>
          </>
        )}
        <div className="flex flex-row justify-between">
          {view ? (
            <div></div>
          ) : (
            <Button
              style={{
                backgroundColor: "#46BDE1",
              }}
              onClick={() => setUiConfigs({ currentStep: 0 })}
              className="mt-3"
            >
              view agreement
            </Button>
          )}
          <Button
            style={{
              backgroundColor: "#46BDE1",
            }}
            onClick={() => setUiConfigs({ currentStep: 2 })}
            className="mt-3"
          >
            view obligations
          </Button>
        </div>
      </div>
    </>
  );
};

export default LegacyAgreementForm2;
