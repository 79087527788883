import {
  ActionIcon,
  Badge,
  Pagination,
  Select,
  Table,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { formatDatetoISO, getActFrequencyText } from "./utilities";
import {
  IconCircle0Filled,
  IconCircleCheck,
  IconCircleFilled,
} from "@tabler/icons-react";
import { acceptObligation, getAcceptObligations } from "../apis/obligationApi";

const Acceptobligation = () => {
  const [configs, setConfigs] = useState({
    loading: true,
    obligations: null,
    count: 0,
  });
  const userData = JSON.parse(localStorage.getItem("userData"));
const businessUnit = userData.businessUnit;
  const department = userData.department;
  const [reload, setReload] = useState(true);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const getObligations = async () => {
      const response = await getAcceptObligations({ department:department, businessUnit: businessUnit,  page: page });
      setConfigs({
        obligations: response.obligations,
        count: response?.count,
        loading: false,
      });
    };
    getObligations();
  }, [page, reload]);
  
  const handleSelectChange = (id, key, value) => {
    setConfigs((prevConfigs) => ({
      ...prevConfigs,
      obligations: prevConfigs.obligations.map((obligation) => 
        obligation.id === id ? { ...obligation, [key]: value } : obligation
      ),
    }));
  };
  const handleAcceptobligation = async({id, recFrequency})=>{
    const response = await acceptObligation({id, recFrequency});
    if (!response.success) {
      alert("obligation not accepted");
    }
    if(response.success){
      setReload(!reload);
    }
  }
  
  return (
    <div>
      <Text className="flex justify-center font-bold text-xl my-4">
        accept obligation/s
      </Text>
      {!configs.loading && configs.obligations?.length > 0 && (
        <>
          <Table striped>
            <thead className="text-center">
              <tr>
                {/* <th style={{ textAlign: "center" }}>select</th> */}
                <th style={{ textAlign: "center" }}>id</th>
                <th style={{ textAlign: "center" }}>title</th>
                <th style={{ textAlign: "center" }}>description</th>
                <th style={{ textAlign: "center" }}>start date</th>
                <th style={{ textAlign: "center" }}>second party</th>
                <th style={{ textAlign: "center" }}>act. frequency</th>
                <th style={{ textAlign: "center" }}>recc. frequency</th>
                <th style={{ textAlign: "center" }}>reminder time</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {!configs.loading &&
                configs.obligations?.length > 0 &&
                configs.obligations.map((row) => {
                  return (
                    <tr>
                      {/* <td>
                        <input
                          type="checkbox"
                          id={`select-${row.id}`}
                          // onChange={(e) =>
                          //   handleCheckboxChange(row.id, e.target.checked)
                          // }
                        />
                      </td> */}
                      <td>{row.id}</td>
                      <td>{row.title}</td>
                      <td>{row.desc.slice(0,60)} <span>...</span></td>
                      <td>{formatDatetoISO(row.startDate)}</td>
                      <td>{row.connectedParty}</td>
                      <td>{getActFrequencyText(row.actFrequency)}</td>
                      <td>
                        {row.actFrequency===2 ?
                        <Select
                        className="text-center"
                        style={{width: '120px'}}
                        data={[
                          { label: "weekly", value: 7 },
                          {label: "fortnight", value: 14},
                          { label: "monthly", value: 30 },
                          { label: "quarterly", value: 90 },
                          {label: "half-yearly", value: 182},
                          { label: "annually", value: 365 },
                        ]}
                          value={row.recFrequency}
                          onChange={(value) => handleSelectChange(row.id, 'recFrequency', value)}

                        />: "--"}
                      </td>
                      <td>{row.reminderTime + "-days"}</td>
                      <td>
                        <ActionIcon
                          onClick={()=>{
                            handleAcceptobligation({id: row.id, recFrequency: row.recFrequency });
                          }}
                        >
                          <IconCircleCheck color="green" />
                        </ActionIcon>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div className="flex flex-row justify-center my-4">
            <Pagination
              color="#46BDE1"
              page={page}
              onChange={(page) => {
                setPage(page);
              }}
              total={Math.ceil(configs.count / 10)}
            />
          </div>
        </>
      )}
      {(configs.obligations?.length === 0 || configs.obligations === null || configs.obligations === undefined)&&
      <Text className="text-center mt-6">no obligations to accept </Text>
      }
    </div>
  );
};

export default Acceptobligation;
