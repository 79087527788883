import axios from "axios";

export const getAttacchedFiles =async ({id})=>{
    return axios.get(`${process.env.REACT_APP_API_URL}/attachedfiles/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }


  export const attachFiles =async ({body})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/attachfiles`, {...body })
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error attaching file", error);
      throw error;
    });
  }

  export const getFile = async({id}) =>{
    return axios.post(`${process.env.REACT_APP_API_URL}/getfile`,{id})
    .then(response => response.data)
    .catch(error =>{
      console.error("There was an error getting file", error);
      throw error;
    });
  }

  export const attachFileToParent =async({extension, contentType})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/attach-file-to-parent`,  {extension, contentType})
    .then(response => response.data)
    .catch(error=>{
      console.error("There was an error attaching file", error);
      throw error;
    })
  }


  export const getReadUrl = async({hostedLink })=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/getreadurl`,  {hostedLink})
    .then(response => response.data)
    .catch(error=>{
      console.error("There was an error getting read url", error);
      throw error;
    })
  }

  export const deleteFile = async ({id})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/deleteFile`, {id})
    .then(response => response.data)
    .catch(error=>{
      console.log("there was an errror deleting file ", error);
    })
  }