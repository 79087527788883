import axios from "axios"

export const loginUser =async ({email, password})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/users/sp-login`, { email, password })
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
}

export const addUser = async({email, password, enabled, role, businessUnit, name, department}) =>{
  return axios.post(`${process.env.REACT_APP_API_URL}/service-providers/new`, {email, password, enabled, role, businessUnit, name, department})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
}

export const updateUser = async({userData})=>{
  return axios.put(`${process.env.REACT_APP_API_URL}/edituser/${userData.id}`, { ...userData})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error updating user", error);
      throw error;
    });
}


export const addBu = async({name, enabled})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/addbu`, {name, enabled})
  .then(response => response.data)
  .catch(error =>{
    console.error("There was an error adding bu", error);
    throw error;
  });
}

export const updateBuDetails = async({id, name, enabled})=>{
  return axios.put(`${process.env.REACT_APP_API_URL}/updateBu/${id}`, {name, enabled})
  .then(response => response.data)
  .catch(error =>{
    console.error("There was an error updating bu", error);
    throw error;
  });
}

export const getBuData  = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getbudata`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu name", error);
    throw error;
  });
} 

export const getBuList = async ({page}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/getbulist`, {page})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu list", error);
    throw error;
  });
}

export const getBuName = async({id})=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getbuname/${id}`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu name", error);
    throw error;
  });
}


export const getBuDetails = async ({page, id}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/getbudetails/${id}`, {page})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu list", error);
    throw error;
  });
}

export const getComplianceStatus = async ({filters, page, pageSize}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/getcomplaincestatus`,{filters, page, pageSize} )
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu list", error);
    throw error;
  });
}
export const fetchBuOptions = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getbuoptions`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}

export const getMasterObligation = async({id})=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getMasterObligation/${id}`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}

export const deleteObligation = async ({id}) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/deleteMasterObligation/${id}` )
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu list", error);
    throw error;
  });
}

export const getTitlesOfAgreement = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/titlesofagreement`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}
export const getNamesofAgreement = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/namesofagreement`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}

export const getStandardObligations = async()=>{
  return axios.get(`${process.env.REACT_APP_API_URL}/getstandardobligations`)
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting bu options", error);
    throw error;
  });
}


export const getUserDetails = async({email})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getUserDetails`, {email})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting user data", error);
    throw error;
  });
}

export const getDocuments = async({page, pageSize})=>{
  return axios.post(`${process.env.REACT_APP_API_URL}/getdocuments`, {page, pageSize})
  .then(response => response.data)
  .catch(error => {
    console.error("There was an error getting documents data", error);
    throw error;
  });
}

