import axios from "axios";

export const getSignedAgreementList =async ({page, filters})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/agreements/SignedagreementList`, {page, filters})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
}


export const createObligationApi =async ({body})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/createobligation`, {...body })
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }


  export const updateObligationApi =async ({body})=>{
    return axios.put(`${process.env.REACT_APP_API_URL}/updateobligation`, {...body })
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  export const bulkUpdateObligationApi =async ({data})=>{
    return axios.put(`${process.env.REACT_APP_API_URL}/bulkupdateobligation`, {data})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  
  export const getObligationsForAgreement = async ({local, agreementId})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/getobligations`, {local, agreementId})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  export const getauditHistory = async ({id})=>{
    return axios.get(`${process.env.REACT_APP_API_URL}/audithistory/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  
  export const getCompletedObligation = async ()=>{
    return axios.get(`${process.env.REACT_APP_API_URL}/completed`)
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  export const getAcceptObligations = async({department, businessUnit,page})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/getacceptobligations`, {department, businessUnit,page})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  export const acceptObligation = async({id, recFrequency})=>{
    return axios.put(`${process.env.REACT_APP_API_URL}/acceptobligation`, {id, recFrequency})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  
  export const getAssignedObligations =async ({department,page, businessUnit, pageSize})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/getassignedobligation`, { department, page, businessUnit, pageSize })
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  export const getActionedObligations =async ({department,page, businessUnit, pageSize})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/getactionedobligation`, { department, page, businessUnit, pageSize })
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  
  export const getObligationDetails = async ({id})=>{
    return axios.get(`${process.env.REACT_APP_API_URL}/getobligationDetails/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  export const getObligationFiles = async ({id})=>{
    return axios.get(`${process.env.REACT_APP_API_URL}/attachedfilestobligation/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  
  export const createLocalAgreement =async ({agreementData})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/createagreement`,{agreementData} )
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  export const getLocalAgreements = async ({page, filters})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/docslocker`, {page, filters})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  export const getLocalAgreementsCSV = async ({page, filters})=>{
    return axios.post(`${process.env.REACT_APP_API_URL}/docslockercsv`, {page, filters})
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  export const getLocalAgreementDetails = async ({id}) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/localagreement/${id}`)
    .then(response => response.data)
    .catch(error => {
      console.error("There was an error logging in!", error);
      throw error;
    });
  }
  
  
  export const getAllObligations = async({page, filters, partyName, status, pageSize}) =>{
    return axios.post(`${process.env.REACT_APP_API_URL}/getallobligations`,{page, filters, partyName, status, pageSize})
    .then(response => response.data)
    .catch(error =>{
      console.error("There was an error getting obligation", error);
      throw error;
    });
  }
  
  
  