import { Stepper } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import LegacyAgreementForm2 from './LegacyAgreementForm2';
import LegacyAgreementForm3 from './LegacyAgreementForm3';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalAgreementDetails } from '../apis/obligationApi';

const ViewAgreement=()=> {
    const [uiConfigs, setUiConfigs] = useState({
        currentStep: 1,
      });
      const [loading, setLoading] = useState(true);
      const { id } = useParams();

      const [file, setFile] = useState(null);
      const [details, setDetails] = useState({
        agreementId : parseInt(id),
        local: true,
        secondParty: "",
        parentAgreementId: 0, 
        typeOfAgreement: "",
        endDate: "",
        businessUnit: 0,
      });
      useEffect(()=>{
        const getAgreement = async()=>{
            const response = await getLocalAgreementDetails({id});
            console.log("reponse: ", response);
            setFile(response.attachedFile);
            setLoading(false);
            setDetails({...details, secondParty: response.secondParty.secondParty.name || response.secondParty.secondParty.indi_name, typeOfAgreement: response.secondParty.typeOfAgreement, endDate: response.secondParty.endDate, businessUnit: response.secondParty.businessUnit })
        }
        getAgreement();
      }, []);
      

  return (<>
    { !loading && 
    <div>
    <Stepper
          color="blue"
          active={uiConfigs.currentStep}
          onStepClick={(step) => {
            setUiConfigs({
              ...uiConfigs,
              currentStep: step,
            });
          }}
          breakpoint="sm"
          style={{ paddingRight: "10px" }}
        >
          <Stepper.Step allowStepSelect={false} label="agreement details">
            
          </Stepper.Step>

          <Stepper.Step allowStepSelect={true} label="create obligation">
            <LegacyAgreementForm2
            setFile={setFile}
            file={file}
            details={details}
            setUiConfigs={setUiConfigs}
            view={true}
             />
          </Stepper.Step>
          <Stepper.Step allowStepSelect={true} label="obligation list">
            <LegacyAgreementForm3 
            details={details}
            setUiConfigs={setUiConfigs}
             />
          </Stepper.Step>
        </Stepper>
    </div>
  }
  </>
  )
}

export default ViewAgreement
